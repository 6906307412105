import { InvestorListItem } from '@gain/rpc/cms-model'
import LoadingButton from '@mui/lab/LoadingButton'
import Card from '@mui/material/Card'
import Chip from '@mui/material/Chip'
import { GridRowProps } from '@mui/x-data-grid/components/GridRow'
import { ReactElement, useCallback, useRef } from 'react'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'

import InputForm, { useInputFormAPI } from '../../common/input-fields'
import SwrDataGrid, { SwrDataGridRef } from '../../common/swr-data-grid'
import SwrRowActions from '../../common/swr-data-grid/swr-row-actions'
import LayoutDataGrid from '../../layout/data-grid'
import { INVESTOR_PROFILE_PATH } from '../route-investor'
import ImportInvestorFundsButton from './import-investor-funds-button'
import { NAV_INVESTORS } from './route-investors-path'

export default function RouteInvestors() {
  const dataGridRef = useRef<SwrDataGridRef>(null)
  const history = useHistory()

  const inputFormAPI = useInputFormAPI({
    createMethod: 'data.createInvestor',
  })

  const handleCreateNewInvestor = useCallback(async () => {
    await inputFormAPI.create({ partial: {} }, (response) => {
      history.push(generatePath(INVESTOR_PROFILE_PATH, { id: response?.id }))
    })
  }, [history, inputFormAPI])

  return (
    <LayoutDataGrid title={NAV_INVESTORS}>
      <Card>
        <SwrDataGrid<'data.listInvestors', InvestorListItem>
          ref={dataGridRef}
          actions={[
            <LoadingButton
              key={'new-investor'}
              loading={inputFormAPI.busy}
              onClick={handleCreateNewInvestor}
              variant={'contained'}>
              New
            </LoadingButton>,
            <ImportInvestorFundsButton key={'import-investor-funds'} />,
          ]}
          columns={[
            {
              field: 'logoFileUrl',
              type: 'avatar',
              headerName: '',
            },
            {
              field: 'name',
              headerName: 'Name',
              width: 216,
              filterable: false,
              flex: 1,
              minWidth: 200,
            },
            {
              field: 'unpublishedAt',
              headerName: 'Unpublished',
              type: 'date',
              minWidth: 150,
            },
            {
              field: 'live',
              headerName: 'Status',
              minWidth: 150,
              type: 'boolean',
              renderCell: ({ value }) => (
                <Chip
                  color={value ? 'success' : 'info'}
                  label={value ? 'Online' : 'Offline'}
                  size={'small'}
                  variant={'outlined'}
                />
              ),
            },
            {
              field: 'actions',
              type: 'actions',
              getActions: SwrRowActions,
            },
          ]}
          label={NAV_INVESTORS}
          method={'data.listInvestors'}
          path={':id'}
          renderRow={({ rowId, row }: GridRowProps, children: ReactElement) => (
            <InputForm
              deleteMethod={'data.deleteInvestor'}
              isDisabled={() => row.live}
              name={'investor'}
              publishMethod={'data.publishInvestor'}
              recordId={`${rowId}`}
              unPublishMethod={'data.unpublishInvestor'}
              validateMethod={'data.validateInvestor'}>
              {children}
            </InputForm>
          )}
          sort={[{ field: 'name', direction: 'asc' }]}
          useFullHeight
        />
      </Card>
    </LayoutDataGrid>
  )
}
