import * as yup from 'yup'

import { yupLinkedInUrl, yupNumber } from '../../common/input-fields/input-field-yup'

export const createPersonSchema = {
  firstName: yup.string().trim().nullable().required(),
  lastName: yup.string().trim().nullable().required(),
  birthYear: yupNumber().min(1925).max(new Date().getFullYear()).nullable(),
}

export const updatePersonSchema = {
  ...createPersonSchema,
  linkedInUrl: yupLinkedInUrl().nullable(),
}
