import { Issue } from '@gain/rpc/cms-model'
import { get, toPath } from 'lodash'
import { useFormState } from 'react-hook-form'

import { useInputFormContext } from './input-form-hooks'

interface IssueStatus {
  hasErrors: boolean
  hasWarnings: boolean
  message: string
}

function getIssueStatus(issues: Issue[]): IssueStatus {
  const hasErrors = issues.some((issue) => issue.type === 'error')
  const hasWarnings = issues.some((issue) => issue.type === 'warning')

  // If there are errors, only include errors in the message, otherwise include
  // the warnings
  const type = hasErrors ? 'error' : hasWarnings ? 'warning' : undefined
  const message = issues
    .filter((issue) => issue.type === type)
    .map((issue) => issue.message)
    .filter(Boolean)
    .join('. ')

  return {
    hasErrors,
    hasWarnings,
    message,
  }
}

/**
 * getWorstIssue accepts either an array or object with issues. Yup provides an
 * object, our backend an array. This function will look for the worst issue in
 * there, picking the first error found, or otherwise the first warning.
 */
function getWorstIssue(issues: Issue[] | Record<string, Issue>): Issue | undefined {
  // First check whether we got an object or array
  let issueArray: Issue[] = []
  if (typeof issues === 'object') {
    issueArray = Object.values(issues)
  } else {
    issueArray = issues
  }

  // Find the most severe issue
  let bestError: Issue | undefined
  let foundError = false
  for (const subItem of issueArray) {
    for (const objectKey of Object.keys(subItem)) {
      foundError = subItem[objectKey].type === 'error'

      if (!bestError || foundError) {
        bestError = subItem[objectKey]
      }

      if (foundError) {
        return bestError
      }
    }
  }

  return bestError
}

/**
 * Returns if there are errors and/or warnings for the given form path and
 * builds the message that can be shown to the users.
 */
export function useInputFieldIssues(...paths: string[]): IssueStatus {
  // Get validation issues
  const inputForm = useInputFormContext()

  // Convert form errors to issues
  const { errors } = useFormState()

  const formIssues = paths.reduce((acc, path) => {
    const error = get(errors, path)

    if (error) {
      // Set defaults for error type and message
      let errorType =
        typeof error.type === 'string' && ['error', 'warning'].includes(error.type)
          ? error.type
          : 'error'
      let errorMessage = error?.message?.toString() ?? ''

      // If it's an array of errors, find the most serious error, use that
      if (Array.isArray(error)) {
        const worstIssue = getWorstIssue(error)
        if (worstIssue) {
          errorType = error.type as string
          errorMessage = worstIssue?.message.toString()
        }
      }

      acc.push({
        type: errorType,
        message: errorMessage,
        path: path,
      })
    }

    return acc
  }, new Array<Issue>())

  // Merge form and validation issues and normalize the path
  const formAndValidationIssues = inputForm.issues.concat(formIssues).reduce((acc, issue) => {
    // Normalize the path to a dot separated format. (e.g. a[1].c > a.1.c)
    const normalizedPath = toPath(issue.path).join('.')

    // Only include issues for requested paths
    if (paths.includes(normalizedPath)) {
      acc.push({
        ...issue,
        path: normalizedPath,
      })
    }

    return acc
  }, new Array<Issue>())

  return getIssueStatus(formAndValidationIssues)
}
